import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import Portal from '@reach/portal';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

const Spinner: FC<React.PropsWithChildren<{ message?: ReactNode; dimmed?: boolean }>> = ({
    message,
    dimmed = false,
}) => (
    <Portal>
        <Container dimmed={dimmed}>
            <Wrapper>
                {message && (
                    <Title variant="large" elementType="h2">
                        {message}
                    </Title>
                )}
                <StyledSpinner />
            </Wrapper>
        </Container>
    </Portal>
);

export default Spinner;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Container = styled.div<{ dimmed: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${({ dimmed }) => (dimmed ? 0.6 : 1)};
    background: ${({ theme }) => theme.colors.neutral[0]};
    position: fixed !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    animation: fadein 100ms forwards ease-out;
    z-index: 3;

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: ${({ dimmed }) => (dimmed ? 0.6 : 1)};
        }
    }
`;

const StyledSpinner = styled.span`
    margin-top: 2rem;
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.secondary[40]};

    border-radius: 100%;
    animation: scaleout 1s infinite ease-in-out;

    transform: translate(-50%, -50%);
    @keyframes scaleout {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
            opacity: 0;
        }
    }
`;
