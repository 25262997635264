import { ApolloQueryResult, QueryOptions, useApolloClient } from '@apollo/client';
import { DocumentNode } from 'graphql';
import omit from 'lodash.omit';
import { NextRouter, useRouter } from 'next/router';
import React, { ComponentType, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary, FallbackProps as REBFallbackProps } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';
import { Url } from 'url';
import Spinner from '../components/designsystem/Spinner';
import ErrorFallback from '../components/ErrorFallback';
import { useTenantContext } from '../context/TenantContext';
import { MeDocument, MeQuery } from '../generated/graphql';
import { ACCESS_TOKEN, login, logout, refresh, REFRESH_TOKEN } from '../utils/auth';
import getApolloErrorType from '../utils/getApolloErrorType';
import getStorageWithExpiry from '../utils/getStorageWithExpiry';
import sentry from '../utils/sentry';

const { captureException } = sentry();

const isLoginRequested = (authCode: unknown): authCode is string => typeof authCode === 'string';
type RedirectFn = (router: NextRouter) => Url | string;

interface FallbackProps extends REBFallbackProps {
    onError: (error: Error, setErrorHandled: (handled: boolean) => void) => void;
}

const Fallback = (props: FallbackProps) => {
    const [errorHandled, setErrorHandled] = useState(true);
    const { brandConfig } = useTenantContext();
    useEffect(() => {
        if (props.error) {
            props.onError(props.error, setErrorHandled);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (errorHandled) {
        return (
            <Spinner
                message={<FormattedMessage defaultMessage="Mijn {island}" values={{ island: brandConfig.name }} />}
            />
        );
    }

    return <ErrorFallback {...props} />;
};

export interface WithAuthProps {
    loading: boolean;
    isAuthorized: boolean;
}

const withAuth = <T extends object>(
    Component: ComponentType<React.PropsWithChildren<T>>,
    options?: {
        /**
         * What to do when the authorizing fails? When given true it redirects to login with the requested url to be restored after login.
         */
        onFail?: RedirectFn | boolean;
        /**
         * What to do when the authorizing succeeds?
         */
        onSucces?: RedirectFn;
        authQuery?: DocumentNode;
        Spinner?: ComponentType<React.PropsWithChildren<unknown>>;
        /**
         * In order to handle its own loading we can use a loading prop passed to the child and show a spinner there
         */
        withLoadingProp?: boolean;
        onAfterLoginSuccess?: (router: NextRouter) => void;

        // don't trigger onFail when credentials are missing
        ignoreMissingCredentials?: boolean;
    }
) => {
    function Enhanced(props: T) {
        const SpinnerComponent = options?.Spinner ?? Spinner;
        const router = useRouter();
        const withAuthCode = isLoginRequested(router.query.authCode);
        const client = useApolloClient();
        const [loading, setLoading] = useState(false);
        const called = useRef(false);
        const succesUrl = useMemo(() => options?.onSucces?.(router), [router]);

        const failUrl = useMemo(() => {
            if (typeof options?.onFail === 'function') {
                return options.onFail(router);
            }

            if (typeof options?.onFail === 'boolean' && options.onFail) {
                // when deeplinking lets start with a clean state
                const [nextPath] = router.asPath.split('?');
                return { pathname: `/login`, query: { next: `/${router.locale}/${nextPath}` } };
            }

            return undefined;
        }, [router]);
        const authQuery = options?.authQuery ?? MeDocument;
        const result = useRef<ApolloQueryResult<MeQuery>>();
        const { brandConfig } = useTenantContext();
        const fetch = useCallback(
            (opts: Omit<QueryOptions, 'query'> = {}) =>
                // @ts-ignore
                client.query<MeQuery>({ query: authQuery, fetchPolicy: 'cache-first', errorPolicy: 'ignore', ...opts }),
            [authQuery, client]
        );

        const removeAuthCode = useCallback(async () => {
            await router.replace({ pathname: router.pathname, query: omit(router.query, 'authCode') }, undefined, {
                shallow: true,
            });
        }, [router]);

        const handleOnFail = useCallback(async () => {
            if (failUrl === 'ignore') {
                setLoading(false);
                return;
            }

            if (failUrl) {
                await router.replace(failUrl);
            } else {
                setLoading(false);
            }
        }, [failUrl, router]);

        const handleMeCheck = useCallback(async () => {
            const storageUtils = getStorageWithExpiry('local');
            if (!storageUtils) {
                return;
            }
            called.current = true;

            if (withAuthCode) {
                const success = await login(router.query.authCode as string);
                if (success) {
                    options?.onAfterLoginSuccess?.(router);
                }
                await removeAuthCode();
            }

            if (!storageUtils.getItem(ACCESS_TOKEN) && !storageUtils.getItem(REFRESH_TOKEN)) {
                if (!options?.ignoreMissingCredentials) {
                    await handleOnFail();
                } else {
                    setLoading(false);
                }
                return;
            }

            if (!storageUtils.getItem(ACCESS_TOKEN)) {
                const success = await refresh();
                if (!success) {
                    await handleOnFail();
                    return;
                }
            }

            result.current = await fetch();

            if (!result.current.data.viewer) {
                const success = await refresh();
                if (!success) {
                    await handleOnFail();
                    return;
                }
                result.current = await fetch({ fetchPolicy: 'network-only' });

                if (!result.current.data.viewer) {
                    await handleOnFail();
                    return;
                }
            }

            if (result.current.data.viewer) {
                if (succesUrl) {
                    await router.replace(succesUrl);
                } else {
                    setLoading(false);
                }
            }
        }, [withAuthCode, fetch, router, removeAuthCode, handleOnFail, succesUrl]);

        useEffect(() => {
            if (router.isReady && !called.current) {
                setLoading(true);
            }
        }, [router.isReady]);

        useEffect(() => {
            if (loading && !called.current) {
                handleMeCheck();
            }
        }, [handleMeCheck, loading]);

        const showSpinner = loading || !called.current;
        const isAuthorized = !!result.current?.data.viewer;

        if (!options?.withLoadingProp && showSpinner) {
            return (
                <SpinnerComponent
                    message={<FormattedMessage defaultMessage="Mijn {island}" values={{ island: brandConfig.name }} />}
                />
            );
        }

        return (
            <ErrorBoundary
                fallbackRender={errProps => (
                    <Fallback
                        onError={async (error, setErrorHandled) => {
                            captureException(error);
                            const errorType = getApolloErrorType(error) ?? '500';
                            if (['401'].includes(errorType)) {
                                const success = await refresh();
                                if (!success) {
                                    logout();
                                    await client.resetStore();
                                    if (failUrl) {
                                        router.replace(failUrl);
                                        return;
                                    }
                                }
                                errProps.resetErrorBoundary();
                            } else {
                                setErrorHandled(false);
                            }
                        }}
                        {...errProps}
                    />
                )}
            >
                <Component {...props} loading={showSpinner} isAuthorized={isAuthorized} />
            </ErrorBoundary>
        );
    }

    Enhanced.displayName = `WithAuthComponent`;

    return Enhanced as ComponentType<React.PropsWithChildren<Omit<T, keyof WithAuthProps>>>;
};

export default withAuth;
