import { FormattedMessage } from 'react-intl';
import EmailConfirm from './svg/EmailConfirm.svg';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import ModalHeader from '@oberoninternal/travelbase-ds/components/layout/ModalHeader';
import { getFontCss } from '@oberoninternal/travelbase-ds/constants/theme';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';

interface Props {
    onTryAgain: () => void;
    brandName?: ReactNode;
}

const CheckEmailMessage: FC<React.PropsWithChildren<Props>> = ({ onTryAgain, brandName }) => (
    <>
        <StyledModalHeader variant="regular">
            <FormattedMessage defaultMessage="Controleer je e-mail" />
        </StyledModalHeader>
        <EmailConfirmIconWrapper>
            <EmailConfirm />
        </EmailConfirmIconWrapper>
        <Description text="center">
            <FormattedMessage
                defaultMessage="We hebben een e-mail gestuurd met de link naar de Mijn {brandName} omgeving. Bekijk je e-mail om in te loggen."
                values={{
                    brandName,
                }}
            />
        </Description>
        <TryAgain>
            <FormattedMessage
                defaultMessage="Geen e-mail ontvangen? {link} of check je spam mailbox."
                values={{
                    link: (
                        <StyledTextButton type="submit" onClick={() => onTryAgain()}>
                            <FormattedMessage defaultMessage="Probeer het opnieuw" />
                        </StyledTextButton>
                    ),
                }}
            />
        </TryAgain>
    </>
);

export default CheckEmailMessage;

const StyledModalHeader = styled(ModalHeader)`
    h1 {
        ${({ theme }) => getFontCss(theme.fontSizes.title.regular)};
        font-weight: 700;
        line-height: 2.8rem;
        color: ${({ theme }) => theme.colors.primary['80']};
    }
`;

const EmailConfirmIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 2.4rem;
`;

const TryAgain = styled(Body)`
    ${({ theme }) => getFontCss(theme.fontSizes.body.small)};
    text-align: center;
    line-height: 1.6rem;
    color: ${({ theme }) => theme.colors.neutral['80']};
`;

const Description = styled(Body)<{ width?: string; text?: string }>`
    margin-top: ${({ theme }) => theme.spacing['50_Semi']};
    margin-bottom: 2.5rem;
    max-width: ${({ width }) => width ?? '100%'};
    text-align: ${({ text }) => text ?? 'start'};
    color: ${({ theme }) => theme.colors.neutral['80']};

    @media screen and (max-width: 720px) {
        max-width: 100%;
    }
`;

const StyledTextButton = styled(TextButton)`
    text-decoration: underline;
    text-decoration-thickness: 0.1rem;
    text-underline-offset: 0.2rem;
    color: ${({ theme }) => theme.colors.primary['80']};

    :hover {
        color: ${({ theme }) => theme.colors.primary['80']};
    }

    span {
        ::after {
            border: none;
        }
    }
`;
