import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Box } from '@rebass/grid';
import { gql } from '@apollo/client';
import React, { FC, ReactNode, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import * as Yup from 'yup';
import messages from '../constants/messages';
import { useSendAuthenticationEmailMutation } from '../generated/graphql';
import { logout } from '../utils/auth';
import Formed from './Formed';
import { getFontCss } from '@oberoninternal/travelbase-ds/constants/theme';
import CheckEmailMessage from './CheckEmailMessage';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { useRouter } from 'next/router';

interface Values {
    email: string;
}

const initialValues: Values = {
    email: '',
};

export const query = gql`
    query Me {
        viewer {
            id
        }
    }
    mutation SendAuthenticationEmail($input: SendAuthenticationEmailInput!) {
        sendAuthenticationEmail(input: $input) {
            success
        }
    }
`;

interface Props {
    title: ReactNode;
    description?: ReactNode;
    helperText?: ReactNode;
    landingUrl: string;
    brandName?: ReactNode;
    className?: string;
    onToggle?: () => void;
}

const LoginContent: FC<React.PropsWithChildren<Props>> = ({
    title,
    description,
    helperText,
    landingUrl,
    brandName,
    className,
    ...props
}) => {
    const { formatMessage } = useIntl();
    const [tryAgain, setTryAgain] = useState(false);
    const [mutate, { loading, called, data }] = useSendAuthenticationEmailMutation();
    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                email: Yup.string()
                    .email(formatMessage(messages.emailError))
                    .required(formatMessage(messages.fieldError)),
            }),
        [formatMessage]
    );
    const router = useRouter();
    const onRegister =
        props.onToggle ??
        function onLogin() {
            router.push('/register');
        };

    return (
        <Content className={className}>
            <Formed<Values>
                initialValues={initialValues}
                handleSubmit={async values => {
                    if (tryAgain) {
                        setTryAgain(false);
                    }
                    // remove current tokens from localStorage otherwise we could get a 401
                    logout();
                    await mutate({
                        variables: {
                            input: {
                                ...values,
                                landingUrl,
                            },
                        },
                    });
                }}
                validationSchema={validationSchema}
                skipPrompt
            >
                {() => {
                    if (called && data?.sendAuthenticationEmail.success && !tryAgain) {
                        return <CheckEmailMessage onTryAgain={() => setTryAgain(true)} brandName={brandName} />;
                    }
                    return (
                        <>
                            <Title>{title}</Title>
                            {description && <Description>{description}</Description>}

                            <StyledFieldSet id="email">
                                <FieldSetLabelWrapper>
                                    <FieldSetLabel>
                                        <FormattedMessage defaultMessage="E-mailadres" />
                                    </FieldSetLabel>
                                </FieldSetLabelWrapper>
                                <EmailField
                                    data-cy="loginEmail"
                                    name="email"
                                    type="email"
                                    placeholder={formatMessage({ defaultMessage: 'voorbeeld@mail.com' })}
                                />
                            </StyledFieldSet>

                            <LoginButton data-cy="login" submitting={loading} size="large" type="submit">
                                <FormattedMessage defaultMessage="Verstuur" />
                            </LoginButton>

                            {helperText && <NeutralBody variant="tiny">{helperText}</NeutralBody>}
                            <CreateAccountWrapper>
                                <StyledBody>
                                    <FormattedMessage defaultMessage="Geen account?" />
                                </StyledBody>
                                <StyledLink
                                    href="#"
                                    onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                                        e.preventDefault();
                                        onRegister();
                                    }}
                                >
                                    <FormattedMessage defaultMessage="Account aanmaken" />
                                </StyledLink>
                            </CreateAccountWrapper>
                        </>
                    );
                }}
            </Formed>
        </Content>
    );
};

export default LoginContent;

const Content = styled(Box)`
    max-width: 44.8rem;
`;

const LoginButton = styled(Button)`
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing['40_Standard']};
    margin-top: 2.4rem;
`;

const NeutralBody = styled(Body)`
    color: ${({ theme }) => theme.colors.neutral['50']};
    text-align: center;
`;

const Description = styled(Body)<{ width?: string; text?: string }>`
    margin-top: ${({ theme }) => theme.spacing['30_Small']};
    margin-bottom: ${({ theme }) => theme.spacing['40_Standard']};
    padding-bottom: ${({ theme }) => theme.spacing['30_Small']};
`;

const EmailField = styled(TextInputField)`
    min-height: ${({ theme }) => theme.spacing['60_Large']};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        min-height: ${({ theme }) => theme.spacing['70_XLarge']};
    }

    ::placeholder {
        color: ${({ theme }) => theme.colors.neutral['40']};
    }
`;

const StyledFieldSet = styled.div`
    margin-bottom: 2rem;
`;

const FieldSetLabelWrapper = styled.div`
    margin-bottom: 1.6rem;
`;

const FieldSetLabel = styled.label`
    ${({ theme }) => getFontCss(theme.fontSizes.body.small)};
    font-weight: 400;
    line-height: 1.6rem;
    color: ${({ theme }) => theme.colors.primary['80']};
`;

const CreateAccountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledBody = styled(Body)`
    ${({ theme }) => getFontCss(theme.fontSizes.body.small)};
    line-height: 1.6rem;
    text-align: center;
`;

const StyledLink = styled.a`
    margin-left: 0.4rem;
    font-weight: 700;
    ${({ theme }) => getFontCss(theme.fontSizes.body.small)};
    line-height: 2.4rem;
    color: #000000;
    text-underline-offset: 0.4rem;
    text-decoration-color: ${({ theme }) => theme.colors.neutral['20']};
    text-decoration-thickness: 0.2rem;
`;
